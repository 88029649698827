@import "../../utils/all";

body.news {
  .pagination {
    font-family: $proxima-nova;
    font-size: rem-calc(14px);
    color: $black;
    list-style: none;
    margin: 60px 0;
    text-align: center;
    a {
      &:not(.pagination__previous-link),
      &:not(.pagination__next-link) {
          padding: 0 10px;
      }
      color: $black;
      text-decoration: none;
    }
    &__item {
      display: inline-block;
    }
    &__page-num {
      &:hover {
        a {
          background: $paginate-cyan;
          padding: 10px;
        }
      }
      &.current {
        font-weight: bold;
        margin-top: -10px;
        padding: 10px;
        background: $paginate-cyan;
        a {
          padding: 0;
        }
      }
    }
    &__previous {
      padding-right: 10px;
      &:before {
        content: '<';
        padding-right: 5px;
      }
    }
    &__next {
      padding-left: 10px;
      &:after {
        content: '>';
        padding-left: 5px;
      }
    }
    &__previous,
    &__next {
      &:hover {
        a {
          text-decoration: underline;
        }
      }
      &.disabled {
        display: none;
      }
    }
  }
}
