@import "../utils/all";

@include critical {
  .modal {
    display: none;
  }
}

@include non-critical {
  body.modal-open {
    overflow: hidden;
  }
  .modal {
    z-index: zIndex(modal);
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);

    @include theme-scoped(life) {
      background-color: rgba($burple, 0.85);
    }

    &__inner {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: white;
      box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.5);
      padding: 25px 15px;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: calc(100% - 30px);

      @include theme-scoped(life) {
        @include respond-above(bg-tablet) {
          width: 736px;
          padding: 25px 50px;
        }
      }

      @include theme-scoped(news) {
        @include respond-above(bg-tablet) {
          width: 736px;
          padding: 40px 50px;
        }
      }
    }

    &__close {
      position: absolute;
      top: 5px;
      right: 5px;
      text-decoration: none;
      line-height: 15px;
      cursor: pointer;
      color: $med-gray;
      font-size: 32px;

      @include theme-scoped(life) {
        color: inherit;
        font-size: 25px;
      }

      @include theme-scoped(news) {
        font-weight: 300;
      }

      @include respond-above(bg-tablet) {
        top: 15px;
        right: 15px;
      }
    }

    &__title {
      font-family: $montserrat;
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 25px;
      color: #000;
      letter-spacing: 1.5px;

      @include theme-scoped(life) {
        color: $red-pink;
        letter-spacing: 1.6px;
      }

      html[lang="en"] & {
        // Uppercase only makes sense for certain languages.
        // Don't apply it to all editions.
        text-transform: uppercase;
      }
    }

    &__subtitle {
      margin-bottom: 15px;
      text-align: center;
      font-family: $proxima-nova-cond;
      font-weight: 800;
      font-size: 22px;
      line-height: 1;
      letter-spacing: .5px;

      @include theme-scoped(life) {
        font-family: $montserrat;
        font-weight: bold;
        font-size: 18px;
        line-height: 1.33;
        letter-spacing: 0;
      }
    }

    &__success {
      font-family: $montserrat;
      font-size: 16px;
      letter-spacing: 0.3px;

      &__link {

      }
    }

    &--open {
      display: block;
    }

    &--left-subtitle {
      .modal__subtitle {
        align-self: flex-start;
        text-align: left;
      }
    }

    &__form {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;

      &__row {
        display: flex;
        width: 100%;
        margin-bottom: 10px;

        &--buttons {
          @include apply-theme(justify-content, flex-end, center);

          margin-bottom: 0;
          margin-top: 25px;
          width: calc(100% + 20px); // to account for the button martins
        }

        &--limit {
          justify-content: flex-end;
        }

        &--input, &--textarea {
          flex-direction: column;
          @include respond-above(sm-tablet) {
            flex-direction: row;
          }
        }
      }

      &__limit {
        font-family: $montserrat;
        font-size: 13px;
        letter-spacing: 0.2px;
        color: $shocking-pink;
      }

      &__label {
        width: 100%;
        flex: 0 0 auto;
        display: flex;
        align-items: center;
        margin-bottom: 5px;

        @include theme-scoped(news) {
          font-family: $proxima-nova;
          font-size: 15px;
          line-height: 22px;
          letter-spacing: .5px;
          font-weight: normal;
        }

        @include theme-scoped(life) {
          font-family: $montserrat;
          font-size: 13px;
          letter-spacing: .2px;
          font-weight: bold;
        }

        @include respond-above(sm-tablet) {
          width: 100px;
          flex: 0 0 100px;
          font-weight: normal;
          margin-right: 15px;
          margin-bottom: 0;
        }

        &--textarea {
          align-items: start;

          @include theme-scoped(news) {
            line-height: 17px;
          }
        }
      }

      &__input {
        flex: 1 1 100%;
        font-family: $montserrat;
        font-size: 13px;
        letter-spacing: 0.2px;
        border: 1px solid $mercury;
        padding: 0 10px;
        min-width: 0;
        @include placeholder {
          font-family: $montserrat;
          font-size: 13px;
          letter-spacing: 0.2px;
          color: $gray;
        }

        &:not(textarea) {
          height: 30px;
        }
      }

      textarea {
        padding: 5px 10px;
        max-width: 100%;
      }

      input {
        &[type="submit"] {
          @include theme-scoped(life) {
            @include cambria-button();
          }
        }

        &[type="cancel"] {
          @include theme-scoped(life) {
            @include cambria-button($light-gray, $light-gray, $light-gray);
          }
        }

        &[type="submit"],
        &[type="cancel"] {
          text-transform: uppercase;
          height: 47px;
          width: 145px;
          margin: 0 10px;
          flex: 0 1 145px;
          cursor: pointer;

          @include theme-scoped(news) {
            border: 0 none;
            height: 40px;
            font-family: $proxima-nova;
            font-weight: normal;
            border-radius: 21px;
            background-color: #000;
            color: #fff;
            font-size: 16px;
            line-height: 20px;
            text-align: center;
            box-sizing: border-box;
          }
        }
      }
    }

    &__login {
      max-width: 375px;

      &__button {
        @include cambria-button($cobalt, $cobalt, $yellow, white, $ice-blue, $cobalt);
        width: 145px;
        height: 45px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 30px auto;
        padding: 0;
        box-sizing: border-box;

        html[lang="en"] & {
          // Uppercase only makes sense for certain languages.
          // Don't apply it to all editions.
          text-transform: uppercase;
        }
      }

      &__signup {
        font-family: $montserrat;
        font-size: 14px;
        text-align: center;
        color: $gray;

        &__link {
          color: $ultramarine-blue;
          text-decoration: none;
          @include non-amp {
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
}
