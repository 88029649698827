@font-face {
  font-family: 'ProximaNovaCondFallback';
  src:local('Arial Bold'),local('Arial');
  font-weight: 700;
  ascent-override: 96.15%;
  descent-override: 25.56%;
  line-gap-override: 0.00%;
  size-adjust: 82.16%;
}
@font-face {
  font-family: 'ProximaNovaCondFallback';
  src:local('Arial');
  font-weight: 500;
  ascent-override: 92.60%;
  descent-override: 24.61%;
  line-gap-override: 0.00%;
  size-adjust: 85.32%;
}
@font-face {
  font-family: 'Sporting Grotesque_Bold Fallback';
  font-style: normal;
  font-weight: 700;
  src: local('Times New Roman Bold'),local('Times New Roman');
  ascent-override: 69.05%;
  descent-override: 37.13%;
  line-gap-override: 1.24%;
  size-adjust: 153.80%;
}
@font-face {
  font-family: 'Sporting Grotesque_Regular Fallback';
  font-style: normal;
  font-weight: 400;
  src: local('Times New Roman');
  ascent-override: 70.50%;
  descent-override: 37.90%;
  line-gap-override: 1.26%;
  size-adjust: 150.64%;
}

$montserrat: Montserrat, sans-serif;
$georgia: Georgia, serif;
$proxima-nova: ProximaNova; //todo add fallback
$proxima-nova-cond: ProximaNovaCond, 'ProximaNovaCondFallback',
sans-serif;
$alfa-slab-one: 'Alfa Slab One';
$pt-mono: "PT Mono";
$helvetica: "Helvetica Neue", Helvetica, Arial, sans-serif;
$fraunces: 'Fraunces', serif;
$publicSans: 'Public Sans', sans-serif;
$sourceSerif: 'Source Serif Pro', serif;
$sporting: 'Sporting Grotesque_Regular', 'Sporting Grotesque_Regular Fallback', serif;
$sporting-bold: 'Sporting Grotesque_Bold', 'Sporting Grotesque_Bold Fallback', serif;
$source-code-pro: 'Source Code Pro', monospace;
$bookmania: 'bookmania', serif;
