@import "../../utils/all";
@include non-critical {
  .alert-banner {
    &__container {
      background-color: $huffpost-primary-700;
      display: flex;
      justify-content: center;
      align-items: center;
      &.breaking {
        background-color: $stop-sign-red;
      }
    }

    &__banner-text {
      color: $white;
      font-family: $proxima-nova-cond;
      font-weight: 700;
      font-size: rem-calc(20px);
      line-height: rem-calc(25px);
      width: 100%;
      text-align: center;
      margin: 13px 16px 13px 16px;
    }
    &__see-more {
      text-decoration: underline;
      margin-left: 7px;
    }
    a, a:hover, a:visited, a:active, a:focus {
      color: $white;
      font-family: $proxima-nova-cond;
      font-weight: 700;
      text-decoration: none;
      display: flex;
    }
    .opinion_template & {
      margin-top: -2px;
      .alert-banner__container {
        width: 100vw;
        position: relative;
        left: calc(-50vw + 50%);
      }
    }
  }
  @include respond-below(md-tablet) {
    .alert-banner {
      &__banner-text {
        &.small {
          font-size: rem-calc(18px);
          line-height: rem-calc(22px);
        }
        &.extra-small {
          font-size: rem-calc(15px);
          line-height: rem-calc(18px);
        }
      }
    }
  }
}
