@import "../utils/all";

.entry .got-a-tip a {
  font-family: $proxima-nova;
  font-weight: 500;
  font-size: rem-calc(14px);
  color: $gray-900;
  text-decoration: underline;
  text-decoration-color: $gray-900;
}
