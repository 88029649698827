@import "../../utils/all";

@include critical {
  .zone.zone--more-in {
    position: relative;
    padding-top: 30px;
    padding-bottom: 30px;
    @include respond-above(phone) {
      padding-top: 35px;
    }
    @include respond-above(bg-tablet) {
      padding-top: 0;
    }
    .zone {
      &__title {
        @include set-container-max(25px);
        @extend %h2-entry-life;
        @include respond-below(bg-tablet) {
          color: $white;
        }
      }
      &__content {
        max-width: calc(100% - 50px);
        @media (min-width: 375px) {
          max-width: calc(100% - 60px);
        }
        @include set-container-max;
        display: flex;
        flex-direction: column;
        @include respond-above(bg-tablet) {
          flex-direction: row;
        }
      }
    }
    &:after {
      content: '';
      position: absolute;
      background-color: $red-pink;
      z-index: -1;
      width: 100%;
      height: 100%;
      top: 0;
      @include respond-above(bg-tablet) {
        bottom: 0;
        top: auto;
        height: 176px;
      }
      @include respond-above(sm-desktop) {
        height: 95px;
      }
      @include respond-above(bg-desktop) {
        height: 120px;
      }
    }
    .card {
      box-shadow: 2px 2px 10px 0 rgba(0, 0, 0, 0.2);
      background-color: $cobalt;
      display: flex;
      height: 104px;
      width: 100%;
      flex: 0 0 104px;
      margin-bottom: 30px;
      @media (min-width: 375px) {
        height: 122px;
        flex: 0 0 122px;
      }
      @media (min-width: 414px) {
        height: 140px;
        flex: 0 0 140px;
      }
      @include non-amp {
        @include respond-above(bg-tablet) {
          margin-right: 30px;
          flex-direction: column;
          width: 217px;
          flex: 0 0 217px;
          height: 235px;
          margin-bottom: 0;
        }
      }
      @include respond-above(sm-desktop) {
        flex-direction: row;
        height: 118px;
        width: auto;
        flex: 1 0 303px;
      }
      @include respond-above(bg-desktop) {
        height: 140px;
        flex: 1 0 360px;
      }
      &:last-child {
        margin-bottom: 0;
        @include respond-above(bg-tablet) {
          margin-right: 0;
        }
      }
      &__image {
        width: 104px;
        height: 104px;
        flex: 0 0 104px;
        @media (min-width: 375px) {
          width: 122px;
          height: 122px;
          flex: 0 0 122px;
        }
        @media (min-width: 414px) {
          width: 140px;
          height: 140px;
          flex: 0 0 140px;
        }
        @include respond-above(bg-tablet) {
          width: 217px;
          height: 122px;
          flex: 0 0 122px;
        }
        @include respond-above(sm-desktop) {
          width: 118px;
          height: 118px;
          flex: 0 0 118px;
        }
        @include respond-above(bg-desktop) {
          width: 140px;
          height: 140px;
          flex: 0 0 140px;
        }
        &__src {
          display: block;
          height: 100%;
          width: 100%;
          @include respond-above(bg-tablet) {
            display: none;
          }
          @include respond-above(sm-desktop) {
            display: block;
          }
          &--secondary {
            display: none;
            @include respond-above(bg-tablet) {
              display: block;
            }
            @include respond-above(sm-desktop) {
              display: none;
            }
          }
        }
      }
      &__text {
        padding-left: 20px;
        padding-top: 23px;
        padding-right: 20px;
        @include respond-above(bg-tablet) {
          padding-left: 30px;
          padding-right: 30px;
        }
        @include respond-above(bg-tablet) {
          padding-left: 20px;
          padding-right: 30px;
        }
      }
      &__label {
        &--top {
          color: $red-pink;
          font-family: $montserrat;
          font-size: rem-calc(11px);
          font-weight: bold;
          text-transform: uppercase;
          letter-spacing: rem-calc(1.1px);
          margin-bottom: 3px;
          @media (min-width: 375px) {
            margin-bottom: 5px;
            font-size: rem-calc(13px);
          }
          @media (min-width: 414px) {
            margin-bottom: 7px
          }
          @include respond-above(bg-tablet) {
            margin-bottom: 3px;
          }
          @include respond-above(sm-desktop) {
            margin-bottom: 5px;
          }
          @include respond-above(bg-desktop) {
            margin-bottom: 10px;
          }
          &:empty {
            display: none;
          }
        }
        &__link {
          text-decoration: none;
        }
      }
      &__headline {
        text-decoration: none;
        &__text {
          color: white;
          font-family: $montserrat;
          font-size: rem-calc(14px);
          font-weight: bold;
          line-height: 1.29;
          display: -webkit-box;
          line-clamp: 3;
          -webkit-line-clamp: 3;
          /* autoprefixer: off */
          -webkit-box-orient: vertical;
          /* autoprefixer: on */
          overflow: hidden;
          @include respond-above(bg-tablet) {
            max-height: 52px; // for ie
          }
        }
      }
      &__label--bottom {
        color: $white;
        margin-top: 5px;
      }
    }
  }
}
@include non-amp {
  .entry.ab-hp_outbrain_remove_test {
    &--variant2,
    &--variant3 {
      @include respond-above(bg-tablet) {
        .zone.zone--more-in {
          .zone__content {
            align-items: end;
            flex-wrap: wrap;
            .card {
              flex-basis: calc(33.33% - ((2/3)* 25px));
              margin-right: 25px;
              margin-bottom: 25px;
              &:nth-child(3),
              &:nth-child(4n+3){
                margin-right: 0;
              }
            }
          }
        }
        &.no-ads {
          .zone.zone--more-in {
            &:after {
              background-color: transparent;
            }
            .zone__content {
              .card {
                margin-right: 25px;
                &:nth-child(4n+3){
                  margin-right: 25px;
                }
                &:nth-child(3n) {
                  margin-right: 0;
                }
              }
            }
          }
        }
      }
    }
  }
}
