@import "../../utils/all";
@import "accent-button";

@include critical {
  .comments {
    &--pull-out {
      position: fixed;
      top: 0;
      bottom: 0;
      right: 0;
      width: 0;
      max-width: 500px;
      transition: width 1s ease;
      background-color: white;
      z-index: zIndex(drawer);
      overflow: hidden;
    }

    &--open {
      width: 100%;
      box-shadow: -1px 0px 10px 1px rgba(0,0,0,0.35);
      overflow: scroll;
      -webkit-overflow-scrolling: 'touch';
    }

    &__close {
      position: absolute;
      font-size: rem-calc(26px);
      top: 5px;
      right: 15px;
      z-index: 1;
      cursor: pointer;
    }

    &__container {
      padding: 15px 10px;
    }
  }
}

@include non-critical {
  .entry {
    &__fb-comments {
      margin-bottom: 20px;
    }
  }
}
