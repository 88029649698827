@import "../../utils/all";
@import "_content-wrapper";

@include critical {
  // Used for Entry Preview
  .preview-placeholder-ad {
    width: 250px;
    height: 250px;
    line-height: 250px;
    border: 1px solid black;
    margin: auto;
    text-align: center;
  }
  .advertisement {
    &.hidden {
      display: none;
    }
    #ad_leaderboard_flex {
      width: initial !important;
    }
    .ad-leaderboard_flex[data-google-query-id] {
      width: 100vw;
      text-align: center;
    }
    &.ad-entry-body-bottom {
      @extend %content-wrapper-base;
      @extend %content-wrapper-queries !optional;
      margin-top: 30px;
      box-sizing: content-box;
    }
    &.ad-below-entry {
      @extend %content-wrapper-base;
      @extend %content-wrapper-queries !optional;
      margin-top: 30px;
      box-sizing: content-box;
      &-tags {
        text-align: center;
        &.treated {
          @include theme-scoped(life) {
            padding-top: 10px;
          }
        }
      }
    }
    &.ad-entry-below-author {
      @extend %content-wrapper-base;
      @extend %content-wrapper-queries !optional;
      margin-bottom: 30px;
      box-sizing: content-box;
    }
    &.ad-entry-body-bottom {
      text-align: center;
    }
  }
  .taboola-container {
    margin-left: auto;
    margin-right: auto;
    .us & {
      min-height: 2200px; // offset CLS - works for desktop + mobile
    }
    .uk & {
      display: none;
    }
  }
  .right-rail-partner {
    min-height: 900px;
  }

  .advertisement.ad-below-entry-recirc {
    margin-bottom: 40px;
    @include set-container-max(30px);
    @include respond-above(sm-desktop) {
      max-width: 750px;
      margin-left: 0;
      @include theme-scoped(opinion) {
        margin-left: auto;
      }
    }
    .ad-native_promo_desk_mw_unit {
      @include respond-above(sm-desktop) {
        display: none;
      }
    }
  }
  #spot_spotlight {
    min-height: 420px;
  }
  #spotim-specific {
    @include theme-scoped(life) {
      margin-bottom: 20px;
    }
    @include respond-below(sm-desktop) {
      @include theme-scoped(news) {
        div[data-spot-im-direction] div[data-reactroot] {
          text-align: center !important;
        }
      }
    }
  }
  .entry.webview {
    .taboola-container {
      padding-top: 30px;
      padding-bottom: 30px;
    }
  }
  .entry.video {
    #spotim-specific {
      margin-bottom: 40px;
    }
    .advertisement.ad-below-entry-recirc {
      @include respond-above(sm-desktop) {
        margin-left: auto;
      }
    }
    .pitc {
      margin-bottom: 40px;
      margin-top: 30px;
      @include respond-above(sm-desktop) {
        max-width: 750px;
        border-bottom: 1px solid $gray86;
      }
    }
  }
  // bpage inline ads - set a minimum height for container to minimize
  //  content shift.  Larger ads may sometimes appear, but this will
  //  prevent issues in most cases.  The size is based the ad that
  //  usually loads plus disclosure size
  .cli-advertisement.advertisement-holder {
    min-height: 270px;
    @include respond-above(bg-tablet) {
      min-height: 110px;
    }
    @include respond-above(sm-desktop) {
      min-height: 270px;
    }
    .advertisement-sticky-wrapper {
      position: sticky;
      top: 50px; // offset for nav bar
    }
  }
}

@include non-amp {
  .openweb-container {
    margin-top: 40px;
    margin-bottom: 40px;
    min-height: 320px;
    @include set-container-max(30px);
  }

  .entry__right-rail {
    .right-rail-sticky {
      height: 100%;
    }
    .ad-right-rail-lower.sticky {
      position: sticky;
      top: 80px;
    }
  }
  // ho_outbrain_remove_test ab test css starts below
  .us.entry {
    .taboola-container,
    .outbrain-module,
    .right-rail-partner,
    .outbrain-replacement-ad,
    .entry__content-list-container .openweb-container {
      display: none;
    }
    // in the hp_outbrain_remove_test ab test we want to show the outbrain module that is inline in the articles regardless of variant
    .entry__content-list {
      .outbrain-module {
        display: block;
      }
    }
  }
  .entry.ab-hp_outbrain_remove_test {
    &--control {
      .taboola-container,
      .outbrain-module,
      .right-rail-partner,
      .entry__content-list-container .openweb-container {
        display: block;
      }
    }
    &--variant1 {
      .outbrain-replacement-ad,
      .entry__content-list-container .openweb-container {
        display: block;
      }
    }
    &--variant2,
    &--variant3 {
      .outbrain-replacement-ad {
        display: block;
      }
      .below-entry-content .openweb-container {
        margin: 20px 0;
        max-width: 100%;
      }
      .ad-recirc-repeating {
        margin: 30px 0;
      }
      @include respond-below(bg-tablet) {
        .ad-recirc-repeating {
          &.even {
            display: none;
          }
        }
      }
      @include respond-above(bg-tablet) {
        .below-entry-content .openweb-container {
          margin: 9px 0 0 0;
          min-height: 250px;
          max-height: 250px;
          width: 100%;
          max-width: 350px;
          display: block;
        }
        .ad-recirc-repeating {
          margin: 24px 0 0 0;
          min-height: 250px;
          max-height: 250px;
          width: calc(33.33% - ((2/3) * 25px));
          div:first-child {
            min-height: 250px;
            max-height: 250px;
            max-width: 350px;
          }

          &.even {
            margin-right: 25px;
          }
        }
        .zone--more-in-life {
          .ad-recirc-repeating {
            width: calc(33.33% - ((2/3) * 30px));
          }
        }
      }
    }
  }

}
