.jp {
  &.life {
    .cli-image__credit {
      text-transform: none;
    }
  }

  .social-buttons--left-nav .social-buttons__title {
    font-size: 0 !important;

    &::before {
      content: "Follow Us";
      font-size: .9375rem;
    }
  }

  &.front-page {
    .splash__text {
      font-weight: 600;
    }
    .splash__headline__text{
      line-height: 1.2em;
    }
    .splash__description {
      font-weight: 100;
      line-height: 1.22;
    }
    .splash--dark .splash__description,
    .splash--dark a {
      text-decoration: none;
     }
     .splash--enterprise .splash__headline__text {
         font-weight: 600;
         font-size: 2rem;
     }
     .splash__byline .card__byline {
         text-transform: none;
     }

     .zone--layout-layout1,
     .zone--layout-layout1b,
     .zone--layout-layout2,
     .zone--layout-layout1b,
     .zone--layout-layout3,
     .zone--layout-layout3b{
      .card__headline__text {
        font-size: 1.5rem;
      }
    }
    .card__headline__text {
      line-height: 1.2;
      font-weight: 600;
    }
    .zone--theme-enterprise {
      .card__headline__text {
        font-family: $proxima-nova;
        font-weight: 700;
      }
    }
    .zone--commerce .zone__content .card__headline__text {
      line-height: 1.15rem;
      padding-top: 4px;
      font-size: 1rem;
    }

    .card__label--top,
    .zone__title {
      text-transform: none;
    }
    .zone--personal .card__byline__author__name {
      text-transform: none;
    }
    .splash__headline {
      &__link {
        line-height: 1.1;
      }
      &--primary-headline {
        line-height: 1;
      }
    }
  }

  .zone--personal {
    .zone__title {
      font-family: $proxima-nova;
      font-weight: 600;
    }
  }
}

.jp.news {
  .newsletter__description {
    font-size: 1rem;
  }
  .newsletter__input__submit {
    font-weight: 700;
    font-size: 1.3rem;
  }
  .social-buttons--follow .social-buttons__title {
    font-size: 1.3rem;
    font-weight: 700;
  }
}

.jp {
  .left-nav__menu__item--heading,
  .subnav__item,
  .site-footer__link {
    text-transform: none;
  }
  .left-nav__footer__link {
    font-size: rem-calc(14px);
  }
}

.jp {
  @include respond-above(sm-desktop) {
    #ad_leaderboard_flex {
      display: flex;
      justify-content: center;
      flex-direction: column;
      min-height: 250px;
    }
  }

  @include respond-below(sm-desktop) {
    #ad-hpj_mw_article_MobilewebTop_BigBanner-1 {
      min-height: 100px;
    }
  }
}

.jp .accent-button {
  padding: 8px 16px;
}
