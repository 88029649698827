@import "../../utils/all";
@include non-amp {
  @keyframes loading {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .loading-message {
    backdrop-filter: blur(4px) brightness(80%);
    display: flex;
    flex-direction: column;
    align-items: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    width: 100%;
    height: 100%;
    justify-content: center;
    z-index: 1;
    backdrop-filter: blur(4px) brightness(80%);
    span {
      font-family: $proxima-nova-cond;
      text-transform: uppercase;
      font-size: rem-calc(20px);
      font-weight: bold;
      line-height: rem-calc(24px);
      color: $white;
      text-shadow: -1px -1px 0 $black, 1px -1px 0 $black, -1px 1px 0 $black, 1px 1px 0 $black;
    }
    .loading-spinner {
      animation: loading 1.25s cubic-bezier(0.5, 0, 0.5, 1) infinite;
      path {
        stroke: $white;
      }
    }
    .loading-message__error {
      display: none;
    }
    &.error {
      .loading-spinner {
        display: none;
      }
      .loading-message__loading {
        display: none;
      }
      .loading-message__error {
        display: block;
      }
    }
    &.loaded {
      display: none;
      z-index: 0;
    }
  }
}
