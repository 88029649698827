@import "../../utils/all";

.social-buttons {
  &--footer {
    .social-buttons {
      &__icons__item {
        &--comments {
          .amp.us & {
            padding: 12px 21px;
            @include theme-scoped(news) {
              color: $gray-700;
            }
            @include theme-scoped(voices) {
              color: $voices-purple;
            }
            @include theme-scoped(opinion) {
              color: $opinion-blue;
            }
          }
          .us & {
            display: flex;
            align-items: center;
            border-radius: 100px;
            padding: 10px 21px;
            font-family: $proxima-nova-cond;
            font-weight: 700;
            font-size: rem-calc(14px);
            text-decoration: none;
          }
        }
      }
    }
    &.social-buttons { // needed for precedence
      display: flex;
      justify-content: center;
      & > *:first-child {
        margin-right: 10px;
      }
      @include respond-below(md-tablet) {
        flex-direction: column-reverse;
        & > *:first-child {
          margin-right: 10px;
        }
      }
    }
  }
  @include non-amp {
    cursor: pointer;
    @include theme-scoped(news) {
      display: inline;
      &__icons {
        display: inline;
      }
    }
    &--side {
      .social-buttons {
        cursor: pointer;
        &__icons {
          display: flex;
          flex-direction: column;
          cursor: pointer;
          &__item {
            &:not(:first-child) {
              margin-top: 5px;
            }
            @include theme-scoped(news) {
              &:not(:last-child) {
                margin-top: 5px;
              }
              button {
                margin-top: 5px;
              }
            }
            &__svg-wrapper {
              display: flex;
              align-items: center;
              width: 40px;
              height: 40px;
              justify-content: center;
              border-radius: 50%;
              @include theme-scoped(news) {
                width: 35px;
                height: 35px;
              }
            }
          }
        }
      }
    }
  }
}
