@import "../utils/all";

.vidible-wrapper {
  position: relative;
  .vidible-placeholder {
    padding-bottom: 56.25%;
  }
  .vdb_player {
    position: absolute;
    top: 0;
  }
}
.connatix-wrapper {
  position: relative;
  .connatix-placeholder {
    padding-bottom: 56.25%;
  }
  .connatix-player {
    width: 100%;
    position: absolute;
    top: 0;
  }
}


@include non-critical {
  .vdb-front-light {
    &.o2-cvs-top-container {
      border-bottom: 1px solid $news-border-gray;
      background-color: #fff;
      color: #000;
    }

    .o2-cvs-header {
      font-family: $proxima-nova-cond;
      font-weight: 700;
      padding-top: 5px;
      padding-bottom: 24px;
      font-size: 24px;
      line-height: 24px;
    }

    &.o2-cvs-narrow-view .o2-cvs-header {
      height: auto;
      padding-bottom: 24px;
    }

    .o2-cvs-header-title {
      letter-spacing: 0.5px;
      font-size: inherit;
      text-transform: uppercase;
      font-style: normal;
      margin: 0 auto;
      color: #000;

      &::after,
      &::before {
        content: '/';
        font-size: inherit;
        color: #0064ff;
      }

      &::before {
        margin-right: 6px;
      }

      &::after {
        margin-left: 6px;
      }
    }

    .o2-cvs-decor-line {
      display: none !important;
    }

    .o2-cvs-video-title {
      font-size: 18px;
      line-height: 1.22;
    }

    .o2-cvs-carousel-outer {
      padding-top: 12px;
      padding-bottom: 24px;
    }

    .o2-cvs-desktop-carousel {
      .o2-cvs-carousel-tile {
        padding: 12px;
        width: 176px;

        &:hover {
          background-color: #333;
          color: #fff;
        }
      }
    }
  }

  @include non-amp {
    // Vidible side view (floating) player.
    .o2-in-side-view {
      // Vidible sets inline css z-index to 9999999. Move it under modal's z-index.
      z-index: zIndex(vidible-side-view-player)!important;
    }
  }
}
