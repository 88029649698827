@include wallpaper-ad(".us.ad_wallpaper:not(.scrolluser)") {
}

.us {
  &.front-page {
    .right-container {
      .advertisement {
        min-width: 300px;
      }
    }
  }
  &.front-page,
  &.apage-flex {
    // us has contributor module next to video so we need to adjust the width of the video container
    .video-support-container {
      @media (min-width: 768px) {
        .video-container {
          width: 100%;
          max-width: 455px;
        }
      }
    }
  }
}
