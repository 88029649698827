@import "../../utils/all";

.entry-footer {
  margin: 24px 0;
  &__links {
    display: flex;
    justify-content: center;
    align-items: baseline;
    span {
      color: $gray-400;
      padding: 0 12px;
    }
  }
  @include theme-scoped(news) {
    .ad-below-entry-tags {
      margin-bottom: 30px;
      background-color: #EBEBE3;
      padding: 30px 0;
    }
    p#info-share {
      text-align: center;
    }
  }
  @include theme-scoped(featured-news) {
    max-width: $featured-text-width;
    margin-left: auto;
    margin-right: auto;
  }
}
