@import "../../utils/all";

$default-grid-template:
  "trend1" auto
  "trend2" auto
  "trend3" auto
  "trend4" auto
  "trend5" auto
  "trend6" auto /
  1fr;

@include respond-above(sm-desktop) {
  .rail--right {
    .zone--trending {
      background-color: $white;
    }
    .zone--trending,
    .zone--trending-special,
    .zone--trending.zone--trending-special { // precedence
      .zone {
        &__content {
          grid-template: $default-grid-template;
          min-height: 680px; // approximate guess -- helps with CLS
        }
      }
      .card {
        display: flex;
        width: 100%;
        max-width: 100%;
        flex-direction: row;
        border-bottom: 1px solid $gainsboro-gray;
        margin: 24px 0 0;
        padding-bottom: 24px;

        &__text {
          flex: 1 1 auto;
          display: flex;
          flex-direction: column;
          justify-content: center;
          min-height: 50px;
          width: calc(50% - 8px);
        }

        &__image__link {
          margin-right: 16px;

          @include respond-above(phone) {
            width: 74px;
            #rightRailTrending & {
              @include theme-scoped(news) {
                width: 64px;
              }
            }
          }
          @include respond-above(sm-tablet) {
            width: 84px;
            #rightRailTrending & {
              @include theme-scoped(news) {
                width: 64px;
              }
            }
          }
          @include respond-above(bg-tablet) {
            width: 92px;
            #rightRailTrending & {
              @include theme-scoped(news) {
                width: 64px;
              }
            }
          }
        }
      }
    }
  }
}

.zone--trending {
  .zone__title {
    font-size: rem-calc(24px);
    @include respond-above(bg-tablet) {
      font-size: rem-calc(28px);
    }
  }
  &.zone--trending-special {
    @import "../zone/shared/phone/medium-img";
    background-color: $mystic;
    padding: 20px 20px 25px 20px;
    h2.zone__title {
      max-width: 1000px;
      justify-content: center;
      align-items: center;
      margin: 0 10px 20px 10px;
      display: flex;
      font-family: $proxima-nova-cond;
      font-weight: 800;
      font-size: rem-calc(24px);
      letter-spacing: 0.4px;
      white-space: pre;
      &:before,
      &:after {
        margin-top: -5px;
        content: '👇';
      }
      &:before {
        margin-right: 11px;
      }
      &:after {
        margin-left: 8px; // corrects left-whitespace in "👇"
      }
    }
    .zone {
      &__content {
        max-width: 1000px;
        margin-left: auto;
        margin-right: auto;
        display: grid;
        grid-gap: 20px 30px;
        grid-template: $default-grid-template;
      }
    }

    .card:nth-child(1) {
      grid-area: trend1;
    }
    .card:nth-child(2) {
      grid-area: trend2;
    }
    .card:nth-child(3) {
      grid-area: trend3;
    }
    .card:nth-child(4) {
      grid-area: trend4;
    }
    .card:nth-child(5) {
      grid-area: trend5;
    }
    .card:nth-child(6) {
      grid-area: trend6;
    }
    .card {
      margin: 0;
    }

    @include respond-above(bg-tablet) {
      .zone {
        &__title {
          margin-bottom: 0;
        }
        &__content {
          max-width: 768px;
        }
      }
      .card {
        padding-bottom: 24px;
        &__headline {
          &__text {
            font-size: rem-calc(18px);
          }
        }
        &__image {
          &__src {
            display: none;
            &--secondary {
              display: inherit;
              width: 92px;
            }
          }
          &__link {
            width: 92px;
          }
        }
      }
    }
    @include respond-above(sm-desktop) {
      .zone {
        &__title {
          max-width: 1170px;
        }
        &__content {
          max-width: 1170px;
          grid-template:
            "trend1 trend2 trend3 trend4 trend5 trend6" auto /
            1fr 1fr 1fr 1fr 1fr 1fr;
          .rail--right & {
            grid-template: $default-grid-template;
          }
        }
      }
      .card {
        border: none;
        flex-direction: column;
        margin: 24px 0 0 0;
        &__text {
          width: 100%;
          flex: initial;
        }
        &__headline {
          &__text {
            font-size: rem-calc(16px);
            line-height: 1.13;
          }
        }
        &__image {
          &__src {
            display: inherit;
            .rail--right & {
              width: 92px;
            }
            &--secondary {
              display: none;
            }
          }
          &__link {
            width: 100%;
            .rail--right & {
              width: 92px;
            }
            margin-bottom: 13px;
          }
        }
      }
    }
  }

  &:not(.zone--trending-special) {
    .zone {
      &__title {
        @extend %h2-entry-news;
      }
    }
  }
  @include respond-above(sm-desktop) {
    .card {
      &__headline {
        text-decoration: none;
        &__text {
          font-weight: 700;
          line-height: rem-calc(26px);
          color: $black;
          font-family: $proxima-nova-cond;
          font-size: rem-calc(18px);
          font-weight: 700;
          line-height: 1.1;
        }
      }

      &__label--bottom {
        text-align: center;
        margin-top: 10px;
      }
    }
  }
}
