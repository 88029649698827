@import "../../utils/all";

.card--news {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  padding: 20px 0;
  border-bottom: 1px solid $mercury;
  @include respond-above(bg-tablet) {
    justify-content: flex-start;
    padding-top: 30px;
    padding-bottom: 40px;
    flex-direction: row;
  }
  @include respond-above(sm-desktop) {
    padding-bottom: 35px;
  }
  @include respond-above(bg-desktop) {
    padding-bottom: 30px;
  }
  .card {
    &__text {
      &__top {
        display: flex;
      }
    }
    &__timestamp {
      display: none;
      @include respond-above(bg-tablet) {
        margin-left: 12px;
        display: initial;
        font-family: $proxima-nova;
        font-size: 13px;
        line-height: 1;
        letter-spacing: 0.3px;
        color: $seriously-gray;
      }
    }
    &__image {
      // margin-left: 20px;
      &__link {
        @include respond-above(bg-tablet) {
          margin-right: 20px;
        }
      }
      &__src {
        .img-sized {
          &__img {
            display: none;
            @include respond-above(bg-tablet) {
              display: initial;
              width: 115px;
              height: 65px;
            }
            @include respond-above(sm-desktop) {
              width: 178px;
              height: 100.4px;
            }
            @include respond-above(bg-desktop) {
              width: 218px;
              height: 123px;
            }
          }
          &__placeholder {
            display: none;
          }
        }
        &--secondary {
          .img-sized {
            &__img {
              display: initial;
              width: 100px;
              height: 100px;
              @include respond-above(bg-tablet) {
                display: none;
              }
            }
          }
        }
      }
    }
    &__label {
      &--top {
        color: $charcoal-gray;
        text-transform: uppercase;
        font-size: 16px;
        font-family: $proxima-nova-cond;
        font-weight: 700;
        letter-spacing: 1px;
        margin-bottom: 6px;
        @include respond-above(bg-tablet) {
          line-height: 1;
          letter-spacing: 0.7px;
          font-size: 13px;
        }
      }
      &__link {
        text-decoration: none;
      }
    }
    &__headline {
      text-decoration: none;
      &__text {
        font-family: $proxima-nova-cond;
        font-weight: 700;
        font-size: 22px;
        line-height: 1.14;
        color: $text-black;
        @include respond-above(sm-desktop) {
          font-size: 26px;
          line-height: 1.08;
          letter-spacing: 0.2px;
        }
      }
    }
    &__byline {
      margin-top: 8px;
      font-family: $proxima-nova;
      font-size: 14px;
      line-height: 1.57;
      color: $seriously-gray;
      .author-list {
        display: flex;
      }
      &__author {
        a {
          color: $seriously-gray;
          text-decoration: none;
        }
      }
    }
    &__label--bottom { // not design-sanctioned...
      margin-top: 9px;
      font-family: $proxima-nova;
      color: $mid-gray;
    }
  }
}
