@mixin wallpaper-ad($selector) {
  @include non-amp {
    #{$selector} {

      @include respond-above(sm-tablet) {
        .left-nav {
          position: fixed;
        }
      }

      @include respond-above(sm-desktop) {

        &.news {
          .main {
            background-color: #fff;
          }
        }

        #cover.ad-rendered {
          line-height: 0; // Don't move the content down
        }

        &.hamburger-open {
          .body-wrap {
            left: 0; // disable content move left
          }

          .left-nav {
            z-index: zIndex(leaderboard-ad);
          }
        }

        .subnav.sticking {
          left: 0;
        }

        &.department {
          .a-page__content {
            max-width: 969px;
          }

          /**
           *  Most wallpaper ads use z-index -1
           *  Rhe default -10 hides the blue life background
           */
          .a-page__label-container::after {
            z-index: -1;
          }
        }

        .entry {
          &__content-and-right-rail-container {
            max-width: 1000px;
          }

          &__content-and-right-rail-container {
            padding-right: 16px;
          }

          &__content-list-container {
            margin-right: 40px;
          }
        }

        &.front-page .zone {
          max-width: 736px - 120px;
        }

        .zone--latest {
          .zone__content {
            grid-template:
              "lata latb" auto
              "latc latd" auto
              "late latf" auto / 303px 303px;

            .card {
              width: 303px;
            }
          }
        } // zone--latest

        .zone--spot {
          &::before {
            width: 100%;
            left: 0;
          }

          .zone__content {
            .card__text {
              width: 357px;
              height: 289px;
              margin: 0 0 0 -25px;
              padding-left: 55px;
              padding-right: 30px;
            }

            .card:not(.card--flip) .card__text {
              width: 357px;
              margin: 0 0 0 -25px;
            }

            .card__headline--long .card__headline__text {
              font-size: 1.625rem;
            }

            .card__image.flipped,
            .card__image__src {
              width: 637px;
            }
          }
        } // zone--spot

        .ad-leaderboard-flex-bottom,
        .ad-leaderboard-flex,
        .zone.zone--featured,
        .entry-head-container,
        .masthead,
        .main,
        .subnav-container,
        .site-footer {
          max-width: 1000px;
          margin-left: auto;
          margin-right: auto;
        }

        .body-wrap > .ad-leaderboard-flex {
          /* don't move the creative down. Affects mostly Italy */
          /* Apply the logic only if the ad is above the header */
          padding: 0;
        }

        .js-leaderboard-sticking {
          &.ad-leaderboard-flex {
            max-width: 100%;
          }
        }

        .a-page__label-container::after {
          max-width: 1000px;
          left: 50%;
          transform: translateX(-50%);
        }

        .zone.zone--featured {
          .zone__title,
          .zone__content {
            max-width: calc(100% - 60px);
            margin-left: auto;
            margin-right: auto;
          }
        }

        // Side share-bar would appear above the wallpaper ad
        &.entry .social-buttons__icons__item__svg-wrapper:not(:hover) {
          background-color: rgba(#fff, .75);
        }
        &.entry.life .social-buttons__icons__item__svg-wrapper:not(:hover) {
          background-color: $cobalt;
        }
        @content
      } // above sm-desktop
    } // selector
  } // non-amp
}
