@import "../../../utils/all";

@include critical {

  .cli-advertisement .advertisement {
    text-align: center;

    @include theme-scoped(featured-news) {
      max-width: $featured-text-width;
      margin: 0 auto;
    }
  }
  body.entry.highline {
    &.webview {
      .entry__content-list {
        .cli-advertisement {
          &.advertisement-holder {
            margin: 40px 0;
          }
        }
      }
    }
  }
}

@include non-critical {
  @include non-amp {
    .entry__content {
      .advertisement {
        &-holder.loaded {
          margin: 40px 0;
        }
      }
    }
  }
}
