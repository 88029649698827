@import "../../utils/all";

$small-horizontal-padding: 30px;
$medium-horizontal-padding: 90px;
$large-horizontal-padding: 100px;

%content-wrapper-base {
  margin-left: auto;
  margin-right: auto;
  max-width: 500px;
  padding: 0 $small-horizontal-padding;
  @include theme-scoped(news) {
    max-width: 544px;
  }
  @include theme-scoped(opinion) {
    max-width: none;
  }
  @include theme-scoped(voices) {
    max-width: none;
  }
}

%content-wrapper-queries {
  @include non-amp {
    @include respond-above(bg-tablet) {
      margin-left: auto;
      margin-right: auto;
      max-width: 588px;
      padding: 0 $medium-horizontal-padding;
    }

    @include respond-above(sm-desktop) {
      padding: 0;
      max-width: none;

      @include theme-scoped(life) {
        .entry {
          padding-left: $large-horizontal-padding;
        }
      }

      .wide & {
        padding-right: $large-horizontal-padding;
      }
    }

    @include theme-scoped(featured-news) {
      @include respond-below(sm-tablet) {
        padding: 0 16px;
      }

      @include respond-above(sm-desktop) {
        max-width: $featured-text-width;
      }
    }
    @include theme-scoped(opinion) {
      @include respond-above(sm-desktop) {
        padding: 0;
      }
    }
    @include theme-scoped(voices) {
      @include respond-above(sm-desktop) {
        padding: 0;
      }
    }
  }
}
