@import "../../../utils/all";
@import "../_content-wrapper";

@include critical {

.cli-embed {
  margin-top: $medium-margin;
  margin-bottom: $medium-margin;
  @include theme-scoped(voices) {
    text-align: center;
  }
  &:not(.cli-embed--full-width):not(.cli-embed--header-media) {
    @extend %content-wrapper-base;
  }
  &:not(.cli-embed--header-media):not(.fake-css-selector-for-specificity) {
    @extend %content-wrapper-queries !optional;
  }
  @include non-amp {
    &--full-width {
      max-width: none !important; // do we need this?
    }
  }

  &__embed-wrapper {
    position: relative;
    > * {
      max-width: 100%;
    }
  }

  &--audio audio {
    width: 450px;
    display: block;
    max-width: 100%;
    outline: 0 none;
    margin: $medium-margin auto;
  }

  &--looper {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
    @include non-amp {
      padding-left: 0 !important;
    }
    .cli-embed__embed-wrapper {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
    }
    video {
      display: block;
      max-width: 100%;
      object-fit: cover;
      &.in-page-anchor:target  {
        padding-top: 80px;
        @include respond-above(bg-tablet) {
          padding-top: 90px;
        }
      }
    }
  }
}
@include non-amp {
  .cli-embed--looper {
    height: auto;
    padding-bottom: 0;
    .cli-embed__embed-wrapper {
      position: relative;
    }
    video {
      display: block;
      max-width: 100%;
    }
  }
  .twitter-tweet,
  .disabled-twitter-tweet {
    min-height: 200px;
  }
}

.cli-embed--header-media {
  .cli-embed__embed-wrapper {
    background-color: $lighter-gray;
  }

  @include respond-above(sm-desktop) {
    .life & {
      min-height: 90px;
    }

    .cli-embed__embed-wrapper {
      @include media-box-shadow();
    }
  }
}

}

@include non-critical {
@include non-amp {
.cli-embed {
  &__embed-wrapper {
    &--cover {
      position: absolute !important;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
    }
  }

  &__back-to-video {
    @include media-box-shadow(3px);
    background-color: $light-gray;
    border: 0;
    color: $white;
    cursor: pointer;
    font-family: $montserrat;
    font-size: rem-calc(12px);
    font-weight: bold;
    left: 5px;
    outline: 0;
    padding: 10px;
    position: absolute;
    text-transform: uppercase;
    top: 5px;
    z-index: 2;
  }
}

}
}
