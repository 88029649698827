@function zIndex($type) {
  @return map-get($z-indexes, $type);
}

@function strip-unit($num) {
  @return $num / ($num * 0 + 1);
}

@function convert-to-rem($value, $base-value: $rem-base) {
  $value: strip-unit($value) / strip-unit($base-value) * 1rem;
  @if $value == 0rem {
    $value: 0;
  }
  @return $value;
}

@function rem-calc($values, $base-value: $rem-base) {
  $max: length($values);
  @if $max == 1 {
    @return convert-to-rem(nth($values, 1), $base-value);
  }
  $remValues: ();
  @for $i from 1 through $max {
    $remValues: append($remValues, convert-to-rem(nth($values, $i), $ase-value));
  }
  @return $remValues;
}
