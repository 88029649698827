@import "../../../utils/all";

.cli-support-huffpost.cli-text {
  width: fit-content;
  max-width: 536px;
  padding: 30px 16px;
  border: 2px solid $red-pink;
  background-color: $hp-pri-50;
  box-sizing: border-box;
  @include respond-below(md-tablet){
    margin-left: 5px;
    margin-right: 5px;
  }
  @include theme-scoped(life) {
    background-color: $life-pri-50;
    border: 2px solid $shopping-pink;
  }
  @include theme-scoped(opinion) {
    background-color: $opinion-blue-50;
    border: 2px solid $red-pink;
  }
  @include theme-scoped(voices) {
    background-color: $voices-light-purple;
    border: 2px solid $voices-sec-800;
  }
  @include non-amp {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
    .is-contributor &,
    .variation {
      display: none;
    }
    .contributor_once &,
    .contributor_canceled & {
      .support-huffpost-control {
        display: none;
      }
    }
    .contributor_once & {
      .contributor-once-variation {
        display: block;
      }
    }
    .contributor_canceled & {
      .contributor-canceled-variation {
        display: block;
      }
    }
  }
  .cli-support-huffpost__title {
    font-size: rem-calc(18px);
    line-height: rem-calc(27px);
    margin-bottom: 16px;
    color: $black;
  }
  .cli-support-huffpost__content-wrapper {
    text-align: left;
    @include respond-above(md-tablet){
      text-align: center;
    }
  }
  .cli-support-huffpost__message-container {
    margin-bottom: 25px;
  }
  p {
    font-size: rem-calc(16px);
    line-height: rem-calc(24px);
  }
  p:not(:first-child):not(.support-huffpost-login) {
    margin: 12px 0;
  }
  a {
    text-decoration: initial;
    box-shadow: initial;
    transition: none;
    display: inline;
    height: auto;
    color: $huffpost-primary-700;
    text-decoration: underline;
    @include theme-scoped(life) {
      color: $life-blue-700;
    }
    @include theme-scoped(opinion) {
      color: $horizon;
    }
    @include theme-scoped(voices) {
      color: $voices-purple-500;
    }
    @include non-amp {
      &:hover {
        text-decoration: none;
        background: none;
        box-shadow: none;
        color: $huffpost-primary-700;
        @include theme-scoped(life) {
          color: $life-blue-700;
        }
        @include theme-scoped(opinion) {
          color: $horizon;
        }
        @include theme-scoped(voices) {
          color: $voices-purple-500;
        }
      }
    }
    &.cli-support-huffpost__support-button {
      color: initial;
      text-decoration: none;
      border-radius: unset;
      width: 100%;
      display: block;
      margin-left: 0;
      text-align: center;
      padding: 14px 0;
      @include respond-above(md-tablet){
        max-width: 308px;
        margin: 0 auto;
      }
      @include theme-scoped(life) {
        background-color: $life-blue-700;
        color: $white;
        border: 1px solid $life-blue-700;
        @include non-amp {
          &:hover {
            color: $life-blue-700;
            background-color: transparent;
          }
        }
      }
      @include theme-scoped(opinion) {
        color: $white;
      }
      @include theme-scoped(voices) {
        &:link, &:visited, &:active {
          .news.voices & {
            color: $white;
            text-decoration: none;
          }
        }
        @include non-amp {
          &:hover {
            .news.voices & {
              color: $voices-purple-500;
            }
          }
        }
      }
    }
  }

  .cli-support-huffpost__message__login-prompt {
    a {
      display: inline;
    }
  }
  .support-huffpost-login {
    font-family: $proxima-nova;
    font-size: rem-calc(13px);
    font-style: normal;
    font-weight: 400;
    text-align: left;
    margin: 4px 0 0 0;
    @include respond-above(md-tablet){
      text-align: center;
    }
  }
  .support-huffpost-login,
  .cli-support-huffpost__message__login-prompt {
    // these classes are added if the user is logged in
    .is-contributor &,
    .non-contributor &,
    .contributor_once &,
    .contributor_canceled & {
      display: none;
    }
  }
  .hidden,
  &.hidden {
    display: none;
  }
}
