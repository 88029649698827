$breakpoints: (
  phone: 375px,
  sm-tablet: 414px,
  md-tablet: 550px,
  bg-tablet: 768px,
  sm-desktop: 1024px,
  bg-desktop: 1280px,
  max: 1440px
);
// access via: `$breakpoint: map-get($breakpoints, tablet);`

$body-max-widths: (
  bg-tablet: 710px,
  sm-desktop: 969px,
  bg-desktop: 1120px
);

$z-indexes: (
  lilsnack-frame: 5000012,
  skip-to-content: 5000011,
  modal: 5000010,
  overlay: 5000009,
  drawer: 5000008,
  sticking-nav: 5000007,
  notification-center-dropwdown: 5000006,
  masthead: 5000005,
  leaderboard-ad: 5000004,
  header: 5000003,
  subnav: 5000003,
  vidible-side-view-player: 5000002,
  sticky: 31,
  top-ad-sticky: 30,
  bottom-ad-sticky: 30,
);

$rem-base: 16;

$include-non-amp: true !default;

// define some default vars
$mobile-nav-height: 40px;
$medium-margin: 30px;
$right-rail-width: 300px;
$left-rail-width: 40px;
$media-box-shadow-size: 7px;

$subnav-bp: 1024px;

$splash-margin-bottom: 50px; // this will get replaced for HPWX-1736
$sidebar-link-font-size: 16px;

// front-page vars
$bg-front-page-card-gap: 32px;
$sm-front-page-card-gap: $bg-front-page-card-gap/2;
$front-zone-margin-bottom: 60px;

// Featured page vars
$featured-body-width: 1280px;
$featured-text-width: 736px;

// apage-flex
$widget-padding: 16px;
$widget-margin: 0 0 calc(60px - #{$widget-padding});
