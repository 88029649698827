@import "../../utils/all";
@import "./subnav";
@import "./editions-menu";
@import "./support-button";
@import "./notificatonCenter";

@include critical {

  &.hamburger-open {
    overflow-y: hidden;
  }

  .hamburger {
    display: flex;
    svg {
      width: 24px;
      height: 24px;
    }
    &--sticky {
      display: flex;
      svg {
        height: 24px;
        width: 24px;
      }
    }
    path {
      @include apply-theme(fill, $gray-900, $white, $news-gray, $news-gray);
    }
    &:hover {
      path {
        fill: $topaz;
      }
    }
  }

  .adchoice {
    position: absolute;
    top: 0;
    min-width: 100px;
    max-width: 120px;
    right: 0;
    a {
      color: #ffffff;
      text-decoration: none;
      font-weight: 500;
      line-height: 14px;
      padding-left: 8px;
      font-size: rem-calc(12px);
      font-family: $proxima-nova;
      img {
        margin-top: -4px;
        width: 20px;
        height: 20px;
        vertical-align: middle;
      }
    }
  }
  .desktop-sticky-nav {
    position: sticky;
    top: 0;
    z-index: zIndex(sticking-nav);
    background-color: $gray-100;
    &:not(.sticking) {
      transition: top 1s ease-in-out;
    }
    @include theme-scoped(life) {
      background-color: $dark-cobalt;
    }
    @include theme-scoped(opinion) {
      background-color: $twilight-blue;
    }
    @include theme-scoped(voices) {
      background-color: $black;
    }
    &.transparent {
      background-color: rgba(245, 245, 245, .5);
      @include theme-scoped(life) {
        background-color: rgba(11, 16, 82, .5);
      }
      @include theme-scoped(opinion) {
        background-color: rgba(245, 252, 255, .5);
      }
      @include theme-scoped(voices) {
        background-color: rgba(0, 0, 0, .5);
      }
    }
  }

  .desktop-nav {
    position: relative;
    background-color: $gray-100;
    @include theme-scoped(life) {
      background-color: $dark-cobalt;
    }
    @include theme-scoped(opinion) {
      background-color: $twilight-blue;
    }
    @include theme-scoped(voices) {
      background-color: $black;
    }
  }

  .nav {
    &__content {
      width: 100%;
      height: 100%;
      display: flex;
      position: relative;
    }
    &__header {
      @include apply-theme(background-color, $white, $cobalt, $white, $black);
      // display: flex;
      // align-items: center;
      // justify-content: center;
      z-index: zIndex(header);
      // top: 0;
      // left: 0;
      // right: 0;
      height: 40px;
      // position: fixed;
      .adchoice {
        text-align: right;
        margin-right: 5px;
        margin-top: 12px;
        right: 0;
        &.has-membership {
          margin-right: 60px;
        }
      }
      @include respond-above(sm-desktop) {
        height: 50px;
      }
    }
    &__left {
      display: flex;
      align-items: center;
      margin-left: 20px;
      z-index: 1; // do we need this?
    }
    &__search {
      display: none;

      &__form {
        &__close {
          display: block;
          color: $white;
          font-family: sans-serif;
          font-size: 28px;
          line-height: 25px;
          margin-right: 5px;
          margin-bottom: 5px;
          font-weight: 300;
        }
      }
    }
    &__logo {
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      &:focus-visible {
        @extend %focus-outline;
      }
      &__svg {
        width: 136px;
        max-height: 28px;
        &--has-support-button {
          display: none;
          @include respond-above(md-tablet) {
            display: block;
          }
        }
      }
      .st2 {
        fill: $black;
        @include theme-scoped(opinion) {
          fill: $black-eel;
        }
        @include theme-scoped(life) {
          fill: $white;
        }
        @include theme-scoped(voices) {
          fill: $white;
        }
      }
      .st3 {
        fill: $topaz;
      }
    }
    &__logo__small {
      display: flex;
      margin-left: 15px;
      &__svg {
        display: initial;
        width: 20px;
        overflow: hidden;
        @include respond-above(md-tablet) {
          display: none;
        }
        .st0 {
          fill: $topaz;
        }
      }
    }
    &__profile {
      display: flex;
      align-items: center;
      position: absolute;
      right: 20px;
      top: 50%;
      transform: translateY(-50%);
      &__image {
        display: block;
        width: 30px;
        height: 30px;
        border-radius: 50%;
      }
      &__subscription-button {
        display: none;
        align-items: center;
        justify-content: center;
        width: 191px;
        height: 40px;
        border-radius: 20px;
        margin-left: 22px;
        font-family: $proxima-nova;
        font-weight: 600;
        color: $white;
        font-size: rem-calc(16px);
        text-decoration: none;
        border: 1px solid $white;
        box-sizing: border-box;
        &:hover {
          border: none;
          background-color: $topaz;
        }
      }
    }
    &__profile-circle {
      width: 20px;
      height: 20px;
      circle,
      path {
        fill: $gray-of-the-beast;
      }
      &__wrapper {
        margin-left: 0;
        display: block;
      }
    }
    &__sign-in {
      display: none;
    }
  }
  @include respond-above(bg-tablet) {
    .nav {
      @include apply-theme(border-color, $opinion-gray-300, $divider-gray, $white, $nero-gray);
      &__search {
        display: block;
        margin-left: 15px;
        margin-top: 3px;
        height: 26px;
        &__svg {
          height: 17px;
          cursor: pointer;
        }
        path {
          @include apply-theme(fill, $news-gray, $white, $opinion-blue);
        }
        &:hover {
          path {
            fill: $topaz;
          }
        }
        position: relative;
        &__form {
          display: none;
        }
        &--open {
          height: 30px;
          width: 250px;
          .nav__search {
            &__svg,
            &__link {
              display: none;
            }
            &__form {
              display: block;
              width: 100%;
              height: 100%;
              display: flex;
              @include apply-theme(background-color, $super-gray, $dark-moderate-blue);
              border-radius: 6px;
              &__svg {
                flex: 0 0 18px;
                height: 18px;
                width: 18px;
                padding: 6px 0 6px 8px;
                path {
                  fill: $white;
                }
              }
              &__input {
                &[type="text"] {
                  padding: 10px;
                  flex: 1 0 auto;
                  display: block;
                  border: none;
                  outline: none;
                  background: none;
                  color: $white;
                  font-size: rem-calc(14px);
                  @include placeholder {
                    font-family: $proxima-nova;
                    font-size: rem-calc(14px);
                    color: $white;
                  }
                }
              }
            }
          }
        }
        .search-results {
          position: absolute;
          top: 30px;
          background-color: rgba($white, 0.9);
          width: 100%;
          ul.sa-list {
            list-style: none;
            .sa-item {
              padding: 5px 10px;
              cursor: pointer;
              &:hover {
                @include apply-theme(background-color, $topaz, $cobalt);
                color: #fff;
              }
            }
          }
        }
      }
    }
  }
  @include respond-above(sm-desktop) {
    .nav {
      border-top: 1px solid;
      border-bottom: 1px solid;
      position: initial;
      &__header {
        position: relative;
        .adchoice {
          display: none;
        }
      }
      &__logo {
        svg {
          width: 168px;
          max-height: 32px;
        }
      }
    }
    // .nav--entry,
    // .nav--apage,
    // .nav--static_template {
    //   .nav {
    //     position: initial;
    //     &__header {
    //       position: relative;
    //     }
    //     &__logo {
    //       svg {
    //         width: 168px;
    //         max-height: 32px;
    //       }
    //     }
    //   }
    // }
  }

  .masthead {
    // Force height to .masthead so when .nav is sticky on below 1024 breakpoint, elements after it don't get pulled up and appear behind the .nav.
    min-height: 40px;

    &.highline {
      background-color: $black;
    }
  }
}

// nav__right:

@include critical {
  .nav {
     // we need the data tribute selector to avoid movement before the component is hydrated
     div[data-component-name="MemberStatus"],
     &__right__container {
       display: flex;
       align-items: center;
       margin-left: auto;
     }
    &__right {
      &__logo {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        &__svg {
          width: 136px;
          max-height: 28px;
        }
        .st2 {
          fill: $black-eel;
          @include theme-scoped(life) {
            fill: $white;
          }
          @include theme-scoped(voices) {
            fill: $white;
          }
        }
        .st3 {
          fill: $topaz;
        }
      }
      &__profile {
        display: flex;
        align-items: center;
        margin-right: 20px;
        &__image {
          display: block;
          width: 30px;
          height: 30px;
          border-radius: 50%;
        }
        &__subscription-button {
          display: none;
          align-items: center;
          justify-content: center;
          margin-left: 37px;
          font-family: $proxima-nova;
          font-weight: bold;
          color: $topaz;
          font-size: rem-calc(14px);
          text-decoration: none;
          &:hover {
            color: $white;
            @include theme-scoped(opinion) {
              color: $black-eel
            }
          }
        }
      }

      &__profile-circle {
        width: 22px;
        height: 22px;
        circle,
        path {
          fill: $gray-900;
          @include theme-scoped(opinion) {
            fill: $huffpost-primary-700;
          }
          @include theme-scoped(life) {
            fill: $white;
          }
          @include theme-scoped(voices) {
            fill: $white;
          }
        }
        &__wrapper {
          margin-left: 0;
          display: block;
        }
      }
      &__sign-in {
        display: none;
      }
    }
  }
  @include respond-above(bg-tablet) {
    .nav {
      &__right {
        &__profile {
          &__image {
            width: 30px;
            height: 30px;
          }
          &__subscription-button {
            display: flex;
          }
        }
        &__profile-circle {
          display: none;
          &__wrapper {
            margin-left: 0;
            display: none;
          }
        }
        &__sign-in {
          display: block;
          box-sizing: border-box;
          font-family: $proxima-nova;
          font-weight: bold;
          color: $gray-900;
          font-size: rem-calc(14px);
          text-decoration: none;
          white-space: nowrap;
          margin-left: 0;
          &:hover {
            color: $huffpost-primary-700;
          }
          @include theme-scoped(life) {
            color: $white;
            &:hover {
              color: $topaz;
            }
          }
          @include theme-scoped(opinion) {
            color: $huffpost-primary-700;
            &:hover {
              color: $black-eel;
            }
          }
          @include theme-scoped(voices) {
            color: $white;
            &:hover {
              color: $topaz;
            }
          }
          &--bubble {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 22px;
            height: 40px;
            border: 1px solid $white;
            border-radius: 20px;
            padding: 0 15px;
            &:hover {
              color: $white;
              background-color: $topaz;
              border-color: $topaz;
            }
          }
        }
      }
    }
  }
}

// needed for search auto-fill and ads:
.masthead {
  position: relative;
  z-index: zIndex(masthead);
  @include respond-below(sm-desktop) {
    position: sticky;
    top: 0;
  }
}
