@import "../../utils/all";
@import "_content-wrapper";

.entry {
  &__corrections {
    color: $gray-900;
    font-family: $proxima-nova;
    font-size: rem-calc(14px);
    font-weight: 500;
    a,
    button {
      color: $gray-900;
      text-decoration: underline;
      text-decoration-color: $gray-900;
    }
  }
}
