
$black: #000000;
$text-black: $black;
$gray7: #111111;
$aqua-marine: #50e3c2;
$topaz: #0dbe98;
$topaz-alt: #06a583;
$huffpost-green: $topaz;
$huffpost-primary-700: #017A5A;
$greenish-cyan: #30F8A0;
$soft-blue: #4a90e2;
$azure: #04acec;
$bright-cyan: #45c8fc;
$charlotte: #92dae4;
$ice-blue: #e7fcff;
$mystic: #e2edef;
$really-light-blue: #d1f9ff;
$shopping-pink: #E8084E;
$shopping-pink-dark:#CF0746;
$dull-blue: #2E5FAF;
$dodger-blue: #3369f8; // or royal blue
$new-blue: #005AFF;
$news-theme-blue: #005ADD;
$dark-green: #004231;
$navy-blue: #0064ff;
$link-blue: #1D3FC4;
$ultramarine-blue: #0D0ECA;
$darker-purple: #29308E;
$cobalt: #171d67;
$focus-outline-purple: #bb22ee;
$darker-purple: #29308E;
$burple: #6a57ff; // TODO get color name from design
$darkish-purple: #712fcb; // TODO get color name from design
$violet: #8707d8;
$lightish-purple: #9d63ec;
$very-light-purple: #b68af1; // TODO get color name from design
$almost-pink: #eae7f7;
$vibrant-purple: #cc00cc;
$shocking-pink: #f711c0;
$red-pink: #f83371;
$yellow: #fcff0d;
$orangy-yellow: #fce2a6;
$white: #ffffff;
$dark-cobalt: #0b1052;
$red-500: #BB3139;
$zesty-orange: #f57023; // TODO get color name from design
$blueberry-blue: #0D60FF; // TODO get color name from design
$tealish: #17fff2; // TODO get color name from design
$light-grayish-cyan: #DCEDEF;
$dark-moderate-blue: #363D9A;
$pink-gray: #ede3e5;
$stop-sign-red: #fe3d2e;
$elections-red: #DC2110;
$paginate-cyan:#e3edef;
$tags-green: #059475;
$tomato: #FF5959;
$horizon: #4f8da3;
$twilight-blue: #F5FCFF;
$cherry-blossom:#F9EFEF;
$black-eel: #434343;
$opinion-blue: #4F76A3;
$opinion-blue-300: #96B6DB;
$opinion-blue-50: #F5FAFF;
$opinion-blue-500: #7096C2;
$voices-light-purple: #F2F1F6;
$voices-light-purple-500: #4b398f;
$voices-pri-300: #9A90C0;
$voices-purple: #392379;
$voices-purple-500: #4F3894;
$voices-pink: #AD1D4D;
$voices-red: #BB3139;
$trending-comment-red: #DC2110;
$voices-orange: #DF5B2A;
$voices-blue: #153F87;
$voices-purple-800: #362474;
$voices-sec-50: #F7EFEF;
$voices-sec-800: #AC3D3E;
$polls-pink: #921D9B;
$polls-pink-light: #EAA9EF;
// fff shades of gray:
$gray: #9b9b9b;
$silver-gray: #F4F4F7;
$gray-50: #FAFAFA;
$gray-100: #F5F5F5;
$gray-300: #E1E1E1;
$gray-400: #BEBEBE;
$gray-500: #9F9F9F;
$gray-600: #767676;
$gray-700: #626262;
$gray-800: #333333;
$gray-900: #222222;
  $news-red-500: $stop-sign-red;
  $ad-background-gray: $gray-100;
$dark-gray: #1a1a1a;
$pretty-dark-gray: #202020;
$gray31: #313131;
$night-rider-gray: $gray-800;
$charcoal-gray: #444444;
$charcoalest-gray: #191919;
$mid-gray: #5c5c5c;
$gray-of-the-beast: #666666;
  $med-gray: $gray-600;
$mid-light-gray: #777777;
$seriously-gray: #757575;
$close-button-gray: #aaa8a8;
$spoils-label-grey: #999999;
$light-gray: #b4b4b4;
$another-gray: #eeeeee;
$explosive-gray: #C4C4C4;
$gray60: #999999;
$gray80: #cccccc;
$gray86: #dbdbdb;
$gray-50: #FAFAFA;
$lighter-gray: #d4d4d4;
$gainsboro-gray: #d8d8d8;
$credit-gray: #a1a1a1;
$mercury: #e5e5e5;
$eclipse-gray: #3a3a3a;
$nero-gray: #2a2a2a;
$almost-white: #f6f6f6; // why is this called "white" in the designs...
$almoster-white: #f9f9f9; // geez
$life-blue-700: #2d3876;
  $divider-gray: $life-blue-700;
  $yet-another-gray: $gray-900;
$life-pri-50: #EBFCFE;
$life-pri-300: #7C92DE;
$life-pri-800: #1A1E66;
$life-pri-900: #090B33;
$soot-gray: #707070;
$super-gray: #282828;
$news-gray: #acacac;
$border-gray: #5d5d5d;
$news-border-gray: #e0e0e0;
$reposition-gray: #676767;
$black-gray: #424242;
  $opinion-subnav-gray: $gray-700;
  $opinion-gray: $gray-500;
$opinion-gray-300: $gray-300;
$opinion-voices-pri-50:#F5FAFF;
$hp-pri-50: #F5FFFD;
  $hp-pri-500: $tags-green;
$sub-nav-gray: #797979;
$sub-nav-pop-gray: #f1f1f5;
$widget-dek-gray: #494949;
// social colors
$facebook: #3b5999;
$twitter: #55acef;
$linkedin: #1184b1;
$pinterest: #cb2028;
$whastapp: #61be4f;
$line: #00c301;
$hatena: #00a4de;
$kakao: #fcd411;
$tumblr: #395877;
$flipboard: #e12828;
$instagram: linear-gradient(160deg, #515ecf, transparent 35%), radial-gradient(circle at 15% 100%, #fed373 10%, #f15245 30%, #d92e7f 62%, #9b36b7);

//front badges
$trending-red: #E61053;
$breaking-red: #DF2D20;
  $liveblog-red: $breaking-red;
$developing-blue: $new-blue;
$exclusive-green: $huffpost-primary-700;
$updated-green: $dark-green;
$top-deal-red: $shopping-pink-dark;
$top-sale-purple: #352C7D;
$last-chance-green: $greenish-cyan;
$going-fast-yellow: #FCFF55;

