.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
}

// a11y fix: Target headline text and add display: block to display focus indicator
.card__headline__text, .card__label__link, .card__headline {
  &:focus-visible {
    @extend %focus-outline-block;
  }
}
