@import "variables";
@import "fonts";
@import "functions";

$include-non-amp: true !default;
$current-page-theme: undetermined !default;
$themes-config: (
  news: (
    selector: '.news',
    extends: null
  ),
  life: (
    selector: '.life',
    extends: null
  ),
  featured-news: (
    selector: '.news.featured',
    extends: news
  ),
  opinion: (
    selector: '.opinion',
    extends: news
  ),
  voices: (
    selector: '.voices',
    extends: news
  ),
);

@function get-theme-config($theme) {
  @return map-get($themes-config, $theme);
}

@function get-theme-selector($theme) {
  $config: get-theme-config($theme);
  @return if($config not null, map-get($config, "selector"), "." + $theme);
}

@function get-base-theme($theme) {
  $config: get-theme-config($theme);
  @return if($config not null, map-get($config, "extends"), null);
}

@function is-child-theme($theme) {
  @return get-base-theme($theme) not null;
}

@mixin respond-above($breakpoint) {
  $breakpoint-value: map-get($breakpoints, $breakpoint);

  @if ($breakpoint-value) {
    @if ($breakpoint-value > 767px) {
      @include non-amp {
        @media (min-width: $breakpoint-value) {
          @content;
        }
      }
    }
    @else {
      @media (min-width: $breakpoint-value) {
        @content;
      }
    }
  }
  @else {
    @if ($breakpoint > 767px) {
      @include non-amp {
        @media (min-width: $breakpoint) {
          @content;
        }
      }
    }
    @else {
      @media (min-width: $breakpoint) {
        @content;
      }
    }
  }
}

@mixin respond-below($breakpoint) {
  $breakpoint-value: map-get($breakpoints, $breakpoint);
  @media (max-width: $breakpoint-value - 1) {
    @content;
  }
}

@mixin respond-between($low, $high) {
  $low-value: map-get($breakpoints, $low);
  $high-value: map-get($breakpoints, $high);
  @media (min-width: $low-value) and (max-width: $high-value) {
    @content;
  }
}

// pass in a min padding for mobile breakpoints
@mixin set-container-max($mw-min-padding: null, $ignore-tablet: false) {
	$tablet-bp: map-get($breakpoints, tablet);
  $sm-desktop-bp: map-get($breakpoints, sm-desktop);
	$bg-desktop-bp: map-get($breakpoints, bg-desktop);
	$max-bp: map-get($breakpoints, max);

  @if ($mw-min-padding) {
    max-width: calc(100% - #{2 * $mw-min-padding});
  }
  @if ($ignore-tablet == false) {
    @include respond-above(bg-tablet) {
  	   max-width: 710px;
    }
  }
  @include respond-above(sm-desktop) {
    max-width: map-get($body-max-widths, sm-desktop);
  }
  @include respond-above(bg-desktop) {
    max-width: map-get($body-max-widths, bg-desktop);
  }
  margin-left: auto;
  margin-right: auto;
  @content
}

@mixin placeholder() {
  &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    @content;
  }
  &::-moz-placeholder { /* Firefox 19+ */
    @content;
  }
  &:-ms-input-placeholder { /* IE 10+ */
    @content;
  }
  &:-moz-placeholder { /* Firefox 18- */
    @content;
  }
}

@mixin critical() {
  @if $include-critical {
    @content;
  }
}
@mixin non-critical() {
  @if $include-non-critical {
    @content;
  }
}
@mixin non-amp {
  @if $include-non-amp {
    @content;
  }
}

@mixin cambria-button(
  $border: $cobalt,
  $text-color: $cobalt,
  $shadow: $cobalt,
  $background: white,
  $background-hover: $ice-blue,
  $shadow-hover: $cobalt,
  $text-hover: $cobalt,
  $border-hover: $cobalt,
) {
  border: solid 2px $border;
  letter-spacing: rem-calc(1.3px);
  text-align: center;
  color: $text-color;
  background-color: $background;
  text-decoration: none;
  position: relative;
  top: 0px;
  left: 0px;
  transition: all 0.2s;
  box-shadow: 3px 3px $shadow;
  cursor: pointer;
  box-sizing: border-box;
  min-width: 0;
  max-width: none;
  user-select: none;
  @include theme-scoped(life) {
    font: bold rem-calc(13px) $montserrat;
  }
  @include theme-scoped(news) {
    font: rem-calc(16px) $proxima-nova;
  }
  @include non-amp {
    &:hover {
      background-color: $background-hover;
      box-shadow: 3px 3px $shadow-hover;
      color: $text-hover;
      border-color: $border-hover;
    }
  }
  &:active {
    box-shadow: 0px 0px $shadow-hover;
    top: 3px;
    left: 3px;
  }
}
@mixin blinking-dot($color: $news-red-500) {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: $color;
  display: inline-block;
  margin-right: 5px;
  &::after {
    content: "";
    display: block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: $color;
    animation: grow-fade 1s infinite;
  }
}

// for LIVE dot
@keyframes grow-fade {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(2);
  }
}

@mixin media-box-shadow($shadow-size: $media-box-shadow-size) {
  @include theme-scoped(life) {
    box-shadow: $shadow-size $shadow-size 0 0 $yellow;
  }
}

@mixin clear-media-box-shadow {
  box-shadow: none;
}

@mixin underline($underline-color: $topaz) {
  background-image: linear-gradient(transparent 90%, $underline-color 50%);
}

@mixin apply-theme($property, $news-value, $life-value, $opinion-value: false, $voices-value: false ) {
  @include theme-scoped(news) {
    #{$property}: $news-value;
  }
  @include theme-scoped(life) {
    #{$property}: $life-value;
  }
  @if $opinion-value {
    @include theme-scoped(opinion) {
      #{$property}: $opinion-value;
    }
  }
  @if $voices-value {
    @include theme-scoped(voices) {
      #{$property}: $voices-value;
    }
  }
}

@mixin theme-scoped($theme) {
  $selector: get-theme-selector($theme);

  @if $current-page-theme == undetermined {
    // $current-page-theme is undetermined. Show the stylesheet scoped to the theme class (e.g. .news/.life.)
    #{$selector} & {
      @content
    }
  }
  @else if ($current-page-theme == $theme) or ($theme == get-base-theme($current-page-theme)) {
    // We have $current-page-theme set to a specific theme.
    // Show the stylesheets ONLY when $theme is the same as $current-page-theme.
    & {
      @content
    }
  }
}

@mixin link-underlines {
  text-decoration: none;
  color: $black;
  box-shadow: inset 0 -2px 0 $topaz;
  @include non-amp {
    &:hover {
      background-color: rgba($topaz,.7);
      padding-top: 2px;
      padding-bottom: 0;
      border-bottom: none;
      transition: background-color 350ms ease-out;
      box-shadow: none;
    }
  }
}

@function strip-unit($value) {
  @return $value / ($value * 0 + 1);
}

@mixin fluid-type($min-vw, $max-vw, $min-font-size, $max-font-size, $add-lh: true) {
  $u1: unit($min-vw);
  $u2: unit($max-vw);
  $u3: unit($min-font-size);
  $u4: unit($max-font-size);

  @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
    & {
      font-size: $min-font-size;
      @if $add-lh {
        line-height: $min-font-size;
      }
      @media screen and (min-width: $min-vw) {
        font-size: calc(#{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
        @if $add-lh {
          line-height: calc(#{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
        }
      }
      @media screen and (min-width: $max-vw) {
        font-size: $max-font-size;
        @if $add-lh {
          line-height: $max-font-size;
        }
      }
    }
  }
}

@mixin last-children-in-row($columns, $className) {
  $first-child-in-any-row: ":nth-child(#{$columns}n + 1)";
  $any-child-in-the-last-row: ":nth-last-child(-n + #{$columns})";
  $first-child-in-last-row: "&#{$first-child-in-any-row}#{$any-child-in-the-last-row}";

  #{$first-child-in-last-row},
  #{$first-child-in-last-row} ~ .#{$className} {
    @content
  }
}

@mixin news-social-button() {
  background: $white;
  border: 1px solid $gray-700;
  svg, g, path {
    fill: $gray-700;
  }
  @include non-amp {
    &:hover {
      color: $white;
      background: $gray-700;
      border: 1px solid $gray-700;
      svg, g, path {
        fill: $gray-100;
      }
    }
  }
}

@mixin video-social-button($color) {
  background: #{$color};
  border: 1px solid $gainsboro-gray;
  @include non-amp {
    &:hover {
      background: $gainsboro-gray;
      border: 1px solid $gainsboro-gray;
      svg, g, path {
        fill: #{$color};
      }
    }
  }
}

@mixin opinion-social-button() {
  background: $white;
  border: 1px solid $opinion-blue;
  svg, g, path {
    fill: $opinion-blue;
  }
  @include non-amp {
    &:hover {
      background: $opinion-blue;
      border: 1px solid $opinion-blue;
      svg, g, path {
        fill: $white;
      }
    }
  }
}

@mixin voices-social-button() {
  background: $voices-light-purple;
  border: 1px solid $voices-purple;
  svg, g, path {
    fill: $voices-purple;
  }
  @include non-amp {
    &:hover {
      background: $voices-purple;
      border: 1px solid $voices-purple;
      svg, g, path {
        fill: $voices-light-purple;
      }
    }
  }
}

%inline-styles {
  // Link
  a {
    transition: background-color 0.3s ease;

    @include theme-scoped(news) {
      text-decoration: none;
      color: $new-blue;
      box-shadow: inset 0 -1px 0 $new-blue;
      @include non-amp {
        &:hover {
          color: $night-rider-gray;
          box-shadow: inset 0 -1px 0 $night-rider-gray;
          text-decoration: none;
        }
      }
    }
    @include theme-scoped(life) {
      color: $ultramarine-blue;
      text-decoration: underline;
      @include non-amp {
        &:hover {
          background-color: $really-light-blue;
          text-decoration: none;
        }
      }
    }
    @include theme-scoped(opinion) {
      color: $horizon;
      box-shadow: inset 0 -1px 0 $horizon;
      @include non-amp {
        &:hover {
          color: $horizon;
          text-decoration: none;
          box-shadow: none;
        }
      }
    }
  }

  // Bold
  strong {
    font-weight: bold;
  }

  // Italic
  em {
    font-style: italic;
  }

  // Strikethrough
  s {
    text-decoration: line-through;
  }
}

%focus-outline {
  outline: 2px solid $focus-outline-purple;
  outline-offset: 2px;
}

%focus-outline-block {
  display: block;
  outline: 2px solid $focus-outline-purple;
  outline-offset: 2px;
}

%h2-entry-news {
  letter-spacing: rem-calc(1.3px);
  text-transform: uppercase;
  color: $black;
  font-family: $proxima-nova-cond;
  font-weight: 800;
  font-size: rem-calc(24px);
  line-height: rem-calc(32px);
  text-align: left;
  @include respond-above(bg-tablet) {
    font-size: rem-calc(28px);
    line-height: rem-calc(30px);
  }
}
%h2-entry-voices {
  color: $voices-purple-800;
  font-family: $sporting;
  font-weight: 700;
  font-size: rem-calc(20px);
  line-height: 130%;
  text-transform: capitalize;
}

%h2-entry-opinion {
  color: $black-eel;
  font-family: $publicSans;
  font-weight: 800;
  text-transform: capitalize;
  display: block;
  font-size: rem-calc(24px);
  line-height: rem-calc(32px);
}

%h2-entry-life {
  font-family: $montserrat;
  color: $life-blue-700;
  font-size: rem-calc(28px);
  line-height: 30px;
  font-weight: 900;
  text-align: left;
  text-transform: uppercase;
  margin-bottom: 24px;
  @include respond-above(bg-tablet) {
    font-size: rem-calc(32px);
  }
}

%h2-entry-module-title {
  @include theme-scoped(news) {
    @extend %h2-entry-news;
  }
  @include theme-scoped(voices) {
    @extend %h2-entry-voices;
  }
  @include theme-scoped(opinion) {
    @extend %h2-entry-opinion;
  }
  @include theme-scoped(life) {
    @extend %h2-entry-life;
  }
}

// card widgets
%widget-card-eyebrow {
  font-family: $proxima-nova-cond;
  font-weight: 800;
  font-size: rem-calc(18px);
  line-height: rem-calc(20px);
  svg {
    margin-right: 6px;
  }
}
%widget-card-title {
  font-family: $proxima-nova-cond;
  font-weight: 900;
  font-size: rem-calc(24px);
  line-height: rem-calc(26px);
  margin-top: 8px;
  margin-bottom: 4px;
  .zone--theme-elections & {
    font-family: $bookmania;
    font-weight: 700;
  }
}
%widget-card-dek {
  font-family: $proxima-nova;
  font-weight: 600;
  font-size: rem-calc(15px);
  line-height: rem-calc(17px);
  margin-bottom: 24px;
}
