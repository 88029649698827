@import "../../utils/all";

@include non-critical {
.site-footer {
  content-visibility: auto;
  &.life {
    background-color: $cobalt;
  }
  &.news, &.highline, &.featured, &.video, &.opinion, &.voices {
    background-color: $black;
  }
  &__wrapper {
    max-width: 1280px;
    padding: 0 20px;
    @include respond-above(bg-desktop) {
      padding: 0 15px;
    }
    margin: auto;
  }
  &__content {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    @include respond-above(bg-tablet) {
      flex-direction: row;
    }
    padding-top: 45px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    @include respond-above(bg-tablet) {
      padding-bottom: 38px;
    }
  }
  &__logo {
    @include respond-below(bg-tablet) {
      align-self: flex-start;
    }
    @include respond-above(bg-desktop) {
      flex: 0 0 176px;
      display: flex;
      align-items: center;
      align-self: stretch;
    }
    &__svg {
      height: 100%;
      width: auto;
      &.small {
        display: none;
        height: 28px;
        margin: rem-calc(18px) rem-calc(8px) rem-calc(8px) 0;
        .st0 {
          fill: $topaz;
        }
      }
      @include respond-between(bg-tablet, bg-desktop) {
        &.large {
          display: none;
        }
        &.small {
          display: unset;
        }
      }
    }
    .st2 {
      fill: white;
    }
    .st3 {
      fill: $topaz;
    }
  }
  &__links {
    flex-grow: 3;
    @include respond-below(bg-tablet) {
      border-bottom: 1px solid hsla(0,0%,100%, 0.1);
    }
    &.sections {
      flex-grow: 2;
      height: rem-calc(120px);
      @include respond-below(bg-tablet) {
        height: auto;
        display: grid;
        gap: 0 8px;
        grid-template-columns: 50% 50%;
      }
    }
    &.corp-links {
      opacity: 0.8;
      height: rem-calc(140px);
      color: $mercury;

      @include respond-below(bg-tablet) {
        height: auto;
        display: grid;
        gap: 0 8px;
        grid-template-columns: 50% 50%;
      }
    }
    display: flex;
    margin: 15px 0 0 30px;
    width: auto;

    flex-direction: column;
    flex-wrap: wrap;

    font-size: rem-calc(12px);
    @include respond-between(bg-tablet, sm-desktop) {
      font-size: rem-calc(10px);
    }
    @include respond-below(bg-tablet) {
      width: 100%;
      margin: 15px 0 0 0;
    }
  }
  &__link {
    text-decoration: none;
    text-transform: uppercase;
    margin-bottom: rem-calc(15px);
    cursor: pointer;

    @include respond-below(bg-tablet) {
      word-break: break-word;
    }

    &:hover {
      color: $topaz;
    }
  }
  &__section {
    color: white;
    font-family: $montserrat;
    letter-spacing: rem-calc(0.43px);
    font-weight: bold;
  }
  &__corp-link {
    font-family: $proxima-nova;
    color: $mercury;
    letter-spacing: rem-calc(0.38px);
    min-width: rem-calc(140px);
  }
  &__branding {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    opacity: 0.8;
    font-family: $proxima-nova;
    font-size: rem-calc(13px);
    color: $mercury;
    padding: 10px 0;
    border-top: 1px solid $mercury;
    text-align: center;
    @include respond-above(bg-tablet) {
      flex-direction: row;
      justify-content: space-between;
      align-items: initial;
    }
    @include respond-below(bg-desktop) {
      padding-bottom: 15px;
    }
    @include respond-below(bg-tablet) {
      * {
        &:not(:last-child) {
          padding-bottom: 10px;
        }
      }
    }
    &__legal {
      color: $white;
      font-weight: 600;
    }
  }

  #ot-sdk-btn {
    font-family: $proxima-nova;
    color: $mercury;
    letter-spacing: rem-calc(0.38px);
    min-width: rem-calc(140px);
    font-size: rem-calc(12px);
    appearance: none;
    border: none;
    padding: 0;
    margin-bottom: rem-calc(15px);
    cursor: pointer;

    @include respond-between(bg-tablet, sm-desktop) {
      font-size: rem-calc(10px);
    }

    @include respond-above(bg-tablet) {
      flex-direction: row;
    }

    &:hover {
      background: transparent;
      color: $topaz;
    }
  }

  #ccpa-data {
    color: currentColor;
    max-width: 8.75rem;
    line-height: 1.4;
    margin-top: -3px;
    overflow: visible;
    height: 0;

    @include respond-below(bg-tablet) {
      height: auto;
      max-width: none;
      margin-bottom: rem-calc(15px);
    }

    img, amp-img {
      margin-left: 2px;
      vertical-align: middle;
    }
  }
  .company-info {
    display: flex;
    color: $credit-gray;
    flex-wrap: wrap;
    flex-direction: column-reverse;

    font-size: rem-calc(12px);
    @include respond-between(bg-tablet, sm-desktop) {
      font-size: rem-calc(10px);
    }
    @include respond-above(bg-tablet) {
      flex-direction: row;
    }

    ul {
      list-style: none;
      flex-basis: 100%;
      display: grid;
      gap: 0 8px;
      grid-template-columns: 50% 50%;
      align-items: center;

      @include respond-above(bg-tablet) {
        display: flex;
        flex-basis: 75%;
        flex-wrap: wrap;
      }

      li {
        padding-right: 15px;

        @include respond-below(bg-tablet) {
          margin-bottom: rem-calc(15px);
        }
      }
    }

    &__partners {
      flex-basis: 100%;
      display: flex;
      margin-top: 15px;
      justify-content: center;

      @include respond-above(bg-tablet) {
        flex-basis: 25%;
      }

      &__image {
        width: 160px;
        background-color: unset;
        margin-bottom: 15px;
      }
    }
  }
}
}
