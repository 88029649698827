@import "../../utils/all";

$header-height--lg: 110px;
$header-height--lg-sticky: 70px;
$header-height--sm: 50px;
$header-breakpoint: bg-tablet;

$small-margin: 15px;
$large-margin: 30px;

@include critical {
  body {
    width: 100%;
    overflow-x: hidden;
    position: relative;
    &.hamburger-open {
      overflow-y: hidden;
      &.body-wrap, .body-wrap {
        left: 414px;
        &::after {
          pointer-events: initial;
          opacity: 0.8;
        }
      }
    }
  }
  .left-nav {
    position: absolute;
    top: 0;
    left: -100%;
    display: none;
    transition: left 0.5 ease;
    debug-property: "original-critical-included";
    width: 100%;
    height: 100%;
    z-index: zIndex(modal) + 3000000;
    -webkit-overflow-scrolling: touch;
    overflow-y: auto;
    @include apply-theme(background-color, $charcoalest-gray, $ice-blue);
    .hamburger-open & {
      position: fixed;
      left: 0%;
      display: flex;
    }

    .transition-active & {
        display: flex;
    }

    .social-buttons--left-nav {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .social-buttons {
        &__title {
          white-space: nowrap;
          font-size: rem-calc($sidebar-link-font-size);
          @include apply-theme(color, $white, $cobalt);
        }
        &__icons {
          display: flex;
          width: 50%;
          justify-content: space-between;
          align-items: center;
          font-family: $proxima-nova;
          &__item {
            &.social-buttons__icons__item--podcast {
              svg {
                width: 18px;
              }
            }
          }
        }
      }
    }

    @include respond-above(sm-tablet) {
      width: 414px;
      left: -414px;
    }
  }

  .body-wrap {
    width: 100%;
    left: 0;
    position: relative;
    &::after {
      z-index: zIndex(overlay);
      pointer-events: none;
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      opacity: 0;
      transition: opacity 0.5s ease;
      @include apply-theme(background-color, rgba($black, 0.65),rgba($burple, 0.85));
    }
    transition: left 0.5s ease;

  }
}
@include non-critical {
  body {
    position: relative;
  }
  .left-nav {
    display: none;
    flex-direction: column;
    justify-content: space-between;
    @include non-amp {
      &__search {
        // display: flex;
        min-width: 100%;
        margin-top: 30px;
        @include apply-theme(background, $white, $light-grayish-cyan);
        height: 40px;
        border-radius: 6px;
        @include respond-above(bg-tablet) {
          display: none;
        }
        &__form {
          display: flex;
          position: relative;
          &__svg {
            height: 40px;
            padding: 0 10px;
            flex: 0 0 18px;
            path {
              @include apply-theme(fill, $seriously-gray, $cobalt);
            }
          }
          &__input {
            &[type="text"] {
              flex: 1 0 auto;
              display: block;
              border: none;
              outline: none;
              background: none;
              @include apply-theme(color, $black, $cobalt);
              color: $cobalt;
              font-size: rem-calc(18px);
              @include placeholder {
                font-family: $proxima-nova;
                font-size: rem-calc(16px);
                letter-spacing: 0.57px;
                @include apply-theme(color, $seriously-gray, $cobalt);
              }
            }
          }
          .search-results {
            position: absolute;
            top: 40px;
            background-color: rgba($white, 0.9);
            width: 100%;
            .sa-list {
              list-style: none;
              .sa-item {
                padding: 5px 10px;
                cursor: pointer;
                &:hover {
                  background-color: $cobalt;
                  color: #fff;
                }
              }
            }
          }
        }
      }
      &__editions-menu {
        display: flex;
        @include apply-theme(background-color, $yet-another-gray, $light-grayish-cyan);
        margin-top: 38px;
        @include respond-above(bg-tablet) {
          display: none;
        }
        &:before {
          margin-left: -30px;
        }
        &:after {
          margin-right: -30px;
        }
        &:before, &:after {
          min-width: 30px;
          content: '';
          @include apply-theme(background-color, $yet-another-gray, $light-grayish-cyan);
        }
        &__toggle {
          text-decoration: underline;
          font-family: $proxima-nova;
          font-size: 12px;
          font-weight: bold;
          @include apply-theme(color, $white, $cobalt);
          @include respond-above(bg-tablet) {
            display: none;
          }
        }
        &__close {
          @include apply-theme(color, $white, $cobalt);
          text-decoration: none;
          font-family: $proxima-nova;
          text-transform: uppercase;
          font-size: rem-calc(10px);
          line-height: rem-calc(24px);
          position: relative;
          &:before {
            content: '\00d7'; // a multiplication sign, more x-ier than an x
            @include apply-theme(color, $white, $cobalt);
            font-size: rem-calc(16px);
            position: absolute;
            left: -11px;
            top: 0px;
          }
        }
      }
    }
    &__footer-links {
      margin-top: 10px;
      display: flex;
    }
    &__footer__link {
      text-decoration: underline;
      font-family: $proxima-nova;
      font-size: $sidebar-link-font-size;
      line-height: 1.27;
      @include apply-theme(color, $white, $cobalt);
    }
    &__copyright {
      font-family: $proxima-nova;
      font-size: $sidebar-link-font-size;
      line-height: 1.27;
      @include apply-theme(color, $white, $cobalt);
    }
    &__body {
      padding: 20px 30px;
    }
    &__top {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding-bottom: 38px;
      border-bottom: 1px solid $gainsboro-gray;
    }

    &__menu {
      display: flex;
      flex-direction: column;
      &__group {
        display: grid;
        grid-template-columns: 1fr 1fr;
      }
      width: 100%;
      // padding-top: 30px; // conflicts with search
      &__item {
        @include apply-theme(color, $white, $cobalt);
        text-decoration: none;
        width: 177px;
        &--heading {
          font-family: $montserrat;
          font-size: 16px;
          font-weight: bold;
          font-style: italic;
          text-transform: uppercase;
          margin-top: 20px;
          white-space: pre;
        }
        &--link {
          padding-right: 5px;
          font-family: $proxima-nova;
          font-size: $sidebar-link-font-size;
          line-height: 1.27;
        }
        &--linked {
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
    &__social {
      @include apply-theme(border-color, $white, $gainsboro-gray);
      border-top: 1px solid;
      border-bottom: 1px solid;
      padding-top: 20px;
      padding-bottom: 20px;
      margin-top: 30px;
      &__text {
        font-family: $proxima-nova;
        font-size: $sidebar-link-font-size;
        line-height: 1.27;
      }
      .social-buttons {
        &__title {
          @include apply-theme(color, $white, $cobalt);
        }
        &__icons {
          display: flex;
          svg {
            path, g {
              @include apply-theme(fill, $white, $cobalt);
            }
          }
        }
      }
    }
  }
  .close-sidebar {
    padding-left: 10px;
    padding-top: 10px;
  }
  .close-sidebar, // AMP close button
  .left-nav__close {
    font-family: sans-serif;

    &:before {
      content: '\00d7'; // a multiplication sign, more x-ier than an x
      @include apply-theme(color, $white, $cobalt);
      cursor: pointer;
      font-size: rem-calc(34px); // this makes the x roughly 20x20
      font-weight: lighter;
      line-height: rem-calc(14px);
    }
    height: 20px;
    width: 20px;
  }
  nav {
    ul {
      li {
        color: #000;
        font-size: rem-calc(16px);
      }
    }
  }
}
