@include wallpaper-ad(".gr.ad_wallpaper") {
}

.gr {
  &.front-page {
    .zone--layout-bucket {
      .zone__title {
        text-transform: none;
      }
    }

    .zone--theme-enterprise {
      .card__headline__text {
        font-family: $proxima-nova;
      }
    }
  }

  .zone--personal {
    .zone__title {
      font-family: $proxima-nova;
      font-weight: 700;
    }
  }
}
