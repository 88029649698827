@import "../../utils/all";
@import "_content-wrapper";

.before-you-go-container {
  margin-top: 40px;
  margin-bottom: 40px;
  padding: 0;
  @extend %content-wrapper-base;
  @extend %content-wrapper-queries !optional;
  @include respond-above(sm-desktop) {
    padding: 0;
  }
}
.before-you-go-title {
  width: 100%;
  margin-bottom: 24px;
  @include theme-scoped(life) {
    @media (max-width: 515px) {
      padding: 0 30px;
    }
  }
  @extend %h2-entry-module-title;
}
